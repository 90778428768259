
import {defineComponent, nextTick, PropType, ref} from 'vue';
import {
  IVatTuResponseBase,
  IVatTuResponseBaseWithApprovedStatus,
  IVatTuWithSoLuongDaXuat
} from '@/core/interfaces/ApiResponses';
import {
  formatCurrencyAndQuantity,
  instanceOfIVatTuResponseBaseWithApprovedStatus,
  instanceOfIVatTuWithSoLuongDaXuat
} from '@/core/helpers/utils';
import {useButtonCustom} from '@/core/hooks/use-button-custom';
import {useRouterCustom} from '@/core/hooks/use-router-custom';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DivCol from '@/layout/_shared/DivCol.vue';
import DivRow from '@/layout/_shared/DivRow.vue';
import SoLuongEditingModal from './SoLuongEditingModal.vue';
import useVatTu from '@/core/hooks/use-vat-tu';

export default defineComponent({
  name: 'add-vat-tu-table-items',

  components: {ButtonCustom, DivCol, DivRow, SoLuongEditingModal},

  emits: ['on-delete', 'so-luong-change', 'update-ghi-chu'],

  setup() {
    const {ButtonTypeColors, ButtonsType, ButtonSizeEnums} = useButtonCustom();

    const {push} = useRouterCustom();

    const editingSoLuongModalRef = ref();

    const {isTextDangerColumn, isValidLabel} = useVatTu();

    return {
      ButtonTypeColors,
      ButtonsType,
      ButtonSizeEnums,
      editingSoLuongModalRef,
      isTextDangerColumn,
      isValidLabel,
      push,
    }
  },

  props: {
    disableGhiChuInput: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<Array<
          IVatTuResponseBase |
          IVatTuResponseBaseWithApprovedStatus |
          IVatTuWithSoLuongDaXuat>
      >,
      default: () => ([]),
    },
    isDeleteButtonDisplay: {
      type: Boolean,
      default: true,
    },
    isHighlightUnapprovedItem: {
      type: Boolean,
      default: false,
    },
    isInfoButtonDisplay: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    isSoLuongEditable: {
      type: Boolean,
      default: false,
    },
    showGhiChuColumn: {
      type: Boolean,
      default: false,
    },
    showGhiChuInput: {
      type: Boolean,
      default: false,
    },
    showPriceAndTotal: {
      type: Boolean,
      default: false,
    }
  },

  watch: {
    items: {
      handler() {
        this.vatTuItems = this.items;
      },
      deep: true,
    },
  },

  data() {
    return {
      selectEditingVatTu: null as null | IVatTuWithSoLuongDaXuat,
      vatTuItems: this.items,
    }
  },

  methods: {
    clickEditButton(vatTu: IVatTuWithSoLuongDaXuat) {
      this.selectEditingVatTu = vatTu;

      nextTick(() => {
        this.editingSoLuongModalRef.modalValue = true;
      });
    },

    isDeleteButtonDisplaying(index: number) {
      return (
          instanceOfIVatTuWithSoLuongDaXuat(this.items[index]) &&
          (this.items[index] as IVatTuWithSoLuongDaXuat).so_luong_da_xuat_kho === 0
      ) || !instanceOfIVatTuWithSoLuongDaXuat(this.items[index]);
    },

    isHighlight(index: number) {
      if (
          this.isHighlightUnapprovedItem &&
          instanceOfIVatTuResponseBaseWithApprovedStatus(this.items[index]) &&
          !(this.items[index] as IVatTuResponseBaseWithApprovedStatus).is_approved
      ) {
        return 'm-0 flex align-items-center text-danger';
      }

      return 'm-0 flex align-items-center';
    },

    formatCurrencyAndQuantity,

    onDeleteVatTu(id: number) {
      this.$emit('on-delete', id)
    },

    onDetailButtonClicked(id) {
      this.push(`/quan-ly/vat-tu/chi-tiet/${id}`);
    },

    onSoLuongChanged({
                       selectingVatTu, so_luong_moi,
                     }: {
      selectingVatTu: IVatTuWithSoLuongDaXuat,
      so_luong_moi: number,
    }) {
      this.editingSoLuongModalRef.modalValue = false;
      this.$emit('so-luong-change', {
        selectingVatTu, so_luong_moi,
      });
    },

    soLuongDisplay(index: number) {
      if (!this.items[index]) return 0;

      if (instanceOfIVatTuWithSoLuongDaXuat(this.items[index])) {
        return formatCurrencyAndQuantity(this.items[index].so_luong);
      }

      return formatCurrencyAndQuantity(this.items[index].so_luong);
    },

    updateGhiChu(value: string, id: number) {
      this.$emit('update-ghi-chu', {
        ghi_chu: value,
        id,
      });
    }
  }
})
